import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useIsMobile from "../../utils/isMobile";

const Solutions = () => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const [tabValue, setTabValue] = useState(0);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section class="contents">
      <div class="solution2">
        <div class="top2">
          <div class="inner">
            <h1 data-aos="fade-up">{t("solution.top.title")}</h1>
            <p class="txt1" data-aos="fade-up">
              {t("solution.top.header.1")}
              <br />
              {t("solution.top.header.2")}
            </p>
            <div class="list">
              <div class="item" data-aos="fade-up">
                <dl>
                  <dt>{t("solution.top.inner.item1.dt")}</dt>
                  <dd>{t("solution.top.inner.item1.dt")}</dd>
                </dl>
              </div>
              <div class="item" data-aos="fade-up">
                <dl>
                  <dt>{t("solution.top.inner.item2.dt")}</dt>
                  <dd>{t("solution.top.inner.item2.dt")}</dd>
                </dl>
              </div>
            </div>
            <p class="txt2" data-aos="fade-up">
              {t("solution.top.inner.txt.1")}
              <br />
              <strong>{t("solution.top.inner.txt.2")}</strong>
            </p>
          </div>
        </div>

        <div class="tab-contents">
          <div class="tab-menu">
            <ul>
              <li>
                <a
                  href="javascript:;"
                  class={tabValue === 0 ? "active" : ""}
                  onClick={() => setTabValue(0)}
                >
                  <span>{t("solution.top.tab.title.1")}</span>
                </a>
              </li>
              <li>
                <a
                  href="javascript:;"
                  class={tabValue === 1 ? "active" : ""}
                  onClick={() => setTabValue(1)}
                >
                  <span>{t("solution.top.tab.title.2")}</span>
                </a>
              </li>
            </ul>
          </div>

          <div
            class="tab-content tab-dsp"
            style={
              isMobile
                ? { display: "block" }
                : tabValue === 0
                ? { display: "block" }
                : { display: "none" }
            }
          >
            <div class="tit">
              <h2>
                {t("solution.top.tab.content.1.header")}
                <br />
                <span>(DSP)</span>
              </h2>
              <p>{t("solution.top.tab.content.1.p")}</p>
            </div>
            <div class="img"></div>
            <div class="txt">
              <h3>{t("solution.top.tab.content.1.txt.header")}</h3>
              <div class="bg-dot">
                <span></span>
                <span></span>
                <span></span>
              </div>
              <p>
                {t("solution.top.tab.content.1.txt.p1")} <br />
                {t("solution.top.tab.content.1.txt.p2")}
                <br />
                {t("solution.top.tab.content.1.txt.p3")}
              </p>
              <p>
                <strong>{t("solution.top.tab.content.1.txt.p4")}</strong>
              </p>
            </div>
            <div class="list">
              <div class="item">
                <dl>
                  <dt>{t("solution.top.tab.content.1.item1.dt")}</dt>
                  <dd>{t("solution.top.tab.content.1.item1.dd")}</dd>
                </dl>
              </div>
              <div class="item">
                <dl>
                  <dt>{t("solution.top.tab.content.1.item2.dt")}</dt>
                  <dd>{t("solution.top.tab.content.1.item2.dd")}</dd>
                </dl>
              </div>
              <div class="item">
                <dl>
                  <dt>{t("solution.top.tab.content.1.item3.dt")}</dt>
                  <dd>{t("solution.top.tab.content.1.item3.dd")}</dd>
                </dl>
              </div>
            </div>
          </div>

          <div
            class="tab-content tab-ssp"
            style={
              isMobile
                ? { display: "block" }
                : tabValue === 0
                ? { display: "none" }
                : { display: "block" }
            }
          >
            <div class="tit">
              <h2>
                {t("solution.top.tab.content.2.header")}
                <br />
                <span>(SSP)</span>
              </h2>
              <p>{t("solution.top.tab.content.2.p")}</p>
            </div>
            <div class="img"></div>
            <div class="txt">
              <h3>{t("solution.top.tab.content.2.txt.header")}</h3>
              <div class="bg-dot">
                <span></span>
                <span></span>
                <span></span>
              </div>
              <p>
                {t("solution.top.tab.content.2.txt.p1")}
                <br />
                {t("solution.top.tab.content.2.txt.p2")}
              </p>
              <p>
                <strong>{t("solution.top.tab.content.2.txt.p3")}</strong>
              </p>
            </div>
            <div class="list">
              <div class="item">
                <dl>
                  <dt>{t("solution.top.tab.content.2.item1.dt")}</dt>
                  <dd>{t("solution.top.tab.content.2.item1.dd")}</dd>
                </dl>
              </div>
              <div class="item">
                <dl>
                  <dt>{t("solution.top.tab.content.2.item2.dt")}</dt>
                  <dd>{t("solution.top.tab.content.2.item2.dd")}</dd>
                </dl>
              </div>
              <div class="item">
                <dl>
                  <dt>{t("solution.top.tab.content.2.item3.dt")}</dt>
                  <dd>{t("solution.top.tab.content.2.item3.dd")}</dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Solutions;
