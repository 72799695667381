import { BrowserRouter, Routes, Route } from "react-router-dom";
import ContactContents from "./components/Contact/ContactContents";
import Footer from "./components/layouts/Footer/Footer";
import MainLayout from "./components/layouts/Main/MainLayout";
import Navbar from "./components/layouts/Navbar/Navbar";
import Main from "./components/Main/Main";
import "./default.css";
import "./store.css";
import "./sub.css";
import "./main.css";
import Solutions from "./components/Solutions/Solutions";
import AITechnology from "./components/AITechnology/AITechnology";
import AIWithPatents from "./components/AIWithPatents/AIWithPatents";
import Company from "./components/Company/Company";
import { Toaster } from "react-hot-toast";
function App() {
  return (
    <>
      <Toaster />
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <MainLayout>
                <Navbar />
                <Main />
                <Footer />
              </MainLayout>
            }
          ></Route>
          <Route
            path="/solutions"
            element={
              <MainLayout>
                <Navbar />
                <Solutions />
                <Footer />
              </MainLayout>
            }
          ></Route>
          <Route
            path="/ai_technology"
            element={
              <MainLayout>
                <Navbar />
                <AITechnology />
                <Footer />
              </MainLayout>
            }
          ></Route>
          <Route
            path="/ai_with_patents"
            element={
              <MainLayout>
                <Navbar />
                <AIWithPatents />
                <Footer />
              </MainLayout>
            }
          ></Route>
          <Route
            path="/company"
            element={
              <MainLayout>
                <Navbar />
                <Company />
                <Footer />
              </MainLayout>
            }
          ></Route>
          <Route
            path="/contact"
            element={
              <MainLayout>
                <Navbar />
                <ContactContents />
                <Footer />
              </MainLayout>
            }
          ></Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
