import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

const Company = () => {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section class="contents">
      <div class="company">
        <div class="inner">
          <div class="page-title">
            <h2 class="title" data-aos="fade-up">
              {t("company.top.header")}
            </h2>
            <p data-aos="fade-up">
              {t("company.top.p1")}
              <br />
              {t("company.top.p2")}
            </p>
          </div>

          <div class="img"></div>

          <div class="txt txt1" data-aos="fade-up">
            <h3>{t("company.txt1.header")}</h3>
            <p>{t("company.txt1.p1")}</p>
            <p>{t("company.txt1.p2")}</p>
            <div class="bg"></div>
            <p>{t("company.txt1.p3")}</p>
            <p>{t("company.txt1.p4")}</p>
            <p>{t("company.txt1.p5")}</p>
          </div>

          <div class="txt txt2" data-aos="fade-up">
            <h3>{t("company.txt2.header")}</h3>
            <p>{t("company.txt2.p1")}</p>
            <div class="bg"></div>
            <p>{t("company.txt2.p2")}</p>
            <p>{t("company.txt2.p3")}</p>
            <p>{t("company.txt2.p4")}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Company;
