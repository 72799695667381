import React from "react";
import { useTranslation } from "react-i18next";
import CopyRight from "../../../assets/img/common/ico_copyright.png";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <footer className="footer">
      <div className="inner">
        <h2>
          <a onClick={() => navigate("/")} style={{ cursor: "pointer" }}>
            <span className="logo">AIX</span>
          </a>
        </h2>

        <div class="link">
          <ul>
            <li>
              <a
                onClick={() => navigate("/solutions")}
                style={{ cursor: "pointer" }}
              >
                {t("header.solutions")}
              </a>
            </li>
            <li>
              <a
                onClick={() => navigate("/ai_technology")}
                style={{ cursor: "pointer" }}
              >
                {t("header.technology")}
              </a>
            </li>
            <li>
              <a
                onClick={() => navigate("/ai_with_patents")}
                style={{ cursor: "pointer" }}
              >
                {t("header.patents")}
              </a>
            </li>
            <li>
              <a
                onClick={() => navigate("/company")}
                style={{ cursor: "pointer" }}
              >
                {t("header.company")}
              </a>
            </li>
            <li>
              <a
                onClick={() => navigate("/contact")}
                style={{ cursor: "pointer" }}
              >
                {t("header.contact")}
              </a>
            </li>
          </ul>
        </div>
        <address>{t("footer.address")}</address>
        <p className="inquiry">
          <span>{t("footer.inquiry")} : </span>
          <a href="mailto:jcg@jcglobeway.com">jcg@jcglobeway.com</a>
        </p>
        <p className="copyright">
          <img src={CopyRight} alt="©" /> JCG Inc. All Rights Reserved.
        </p>

        <div className="sns">
          <ul>
            <li>
              <a href="#" className="twitter">
                twitter
              </a>
            </li>
            <li>
              <a href="#" className="facebook">
                facebook
              </a>
            </li>
            <li>
              <a href="#" className="instagram">
                instagram
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
