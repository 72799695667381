import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const Main = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section class="contents">
      <div class="top">
        <div class="inner">
          <h1 data-aos="fade-up">
            {t("main.top.header.1")}
            <br />
            {t("main.top.header.2")}
          </h1>
          <p>{t("main.top.p")}</p>
          <div class="btns">
            <a
              class="btn"
              onClick={() => navigate("/solutions")}
              style={{ cursor: "pointer" }}
            >
              {t("main.top.btn1")}
            </a>
            <a
              class="btn"
              onClick={() => navigate("/ai_technology")}
              style={{ cursor: "pointer" }}
            >
              {t("main.top.btn2")}
            </a>
          </div>
        </div>
      </div>

      <div class="roi">
        <div class="inner">
          <h2>
            <span>{t("main.inner.header.1")} </span>
            {t("main.inner.header.2")}
            <br />
            {t("main.inner.header.3")} -{" "}
            <span>{t("main.inner.header.4")} </span>
            <br />
            <em>{t("main.inner.header.5")}</em>
          </h2>
          <div class="list">
            <div class="item" data-aos="fade-up">
              <dl>
                <dt>{t("main.inner.item1.dt")}</dt>
                <dd>{t("main.inner.item1.dd")}</dd>
              </dl>
            </div>
            <div class="item" data-aos="fade-up">
              <dl>
                <dt>{t("main.inner.item2.dt")}</dt>
                <dd>{t("main.inner.item2.dd")}</dd>
              </dl>
            </div>
            <div class="item" data-aos="fade-up">
              <dl>
                <dt>{t("main.inner.item3.dt")}</dt>
                <dd>{t("main.inner.item3.dd")}</dd>
              </dl>
            </div>
            <div class="item" data-aos="fade-up">
              <dl>
                <dt>{t("main.inner.item4.dt")}</dt>
                <dd>{t("main.inner.item4.dd")}</dd>
              </dl>
            </div>
            <div class="item" data-aos="fade-up">
              <dl>
                <dt>{t("main.inner.item5.dt")}</dt>
                <dd>{t("main.inner.item5.dd")}</dd>
              </dl>
            </div>
            <div class="item" data-aos="fade-up">
              <dl>
                <dt>{t("main.inner.item6.dt")}</dt>
                <dd>{t("main.inner.item6.dd")}</dd>
              </dl>
            </div>
          </div>
          <div class="bg-dot">
            <span></span>
            <span></span>
            <span></span>
          </div>
          <p class="txt">
            <dd>{t("main.inner.txt")}</dd>
          </p>
        </div>
      </div>

      <div class="solutions">
        <div class="inner">
          <h2 data-aos="fade-up">
            <dd>{t("main.inner.solutions.title")}</dd>
          </h2>
          <p data-aos="fade-up">{t("main.inner.solutions.description")}</p>
          <div class="btns" data-aos="fade-up">
            <a
              onClick={() => navigate("/solutions")}
              style={{ cursor: "pointer" }}
              class="btn"
            >
              {t("main.inner.solutions.btn1")}
            </a>
            <a
              onClick={() => navigate("/solutions")}
              style={{ cursor: "pointer" }}
              class="btn"
            >
              {t("main.inner.solutions.btn2")}
            </a>
          </div>
        </div>
      </div>

      <div class="technology">
        <div class="inner">
          <h2 data-aos="fade-up">{t("main.inner.technology.title")}</h2>
          <p data-aos="fade-up">{t("main.inner.technology.description")}</p>
          <div class="btns" data-aos="fade-up">
            <a
              onClick={() => navigate("/ai_technology")}
              style={{ cursor: "pointer" }}
              class="btn"
            >
              {t("main.inner.technology.btn1")}
            </a>
            <a
              onClick={() => navigate("/ai_technology")}
              style={{ cursor: "pointer" }}
              class="btn"
            >
              {t("main.inner.technology.btn2")}
            </a>
          </div>
        </div>
      </div>

      <div class="ai-with-patents">
        <div class="inner">
          <h2>{t("main.inner.patents.inner.header")}</h2>
          <p>{t("main.inner.patents.inner.p")}</p>
          <div class="list">
            <div class="item" data-aos="fade-up">
              <dl>
                <dt>{t("main.inner.patents.item1.dt")}</dt>
                <dd>{t("main.inner.patents.item1.dd")}</dd>
              </dl>
              <a
                onClick={() => navigate("/ai_with_patents")}
                style={{ cursor: "pointer" }}
                class="btn-more"
              >
                {t("main.inner.a")}
              </a>
            </div>
            <div class="item" data-aos="fade-up">
              <dl>
                <dt>{t("main.inner.patents.item2.dt")}</dt>
                <dd>{t("main.inner.patents.item2.dd")}</dd>
              </dl>
              <a
                onClick={() => navigate("/ai_with_patents")}
                style={{ cursor: "pointer" }}
                class="btn-more"
              >
                {t("main.inner.a")}
              </a>
            </div>
            <div class="item" data-aos="fade-up">
              <dl>
                <dt>{t("main.inner.patents.item3.dt")}</dt>
                <dd>{t("main.inner.patents.item3.dd")}</dd>
              </dl>
              <a
                onClick={() => navigate("/ai_with_patents")}
                style={{ cursor: "pointer" }}
                class="btn-more"
              >
                {t("main.inner.a")}
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="contact-us" data-aos="fade-up">
        <div class="inner">
          <h2>{t("main.inner.contact.header")}</h2>
          <p>
            {t("main.inner.contact.p1")} <br />
            {t("main.inner.contact.p2")}
          </p>
          <a
            onClick={() => navigate("/contact")}
            style={{ cursor: "pointer" }}
            class="btn-more"
          >
            {t("main.inner.a")}
          </a>
        </div>
      </div>
    </section>
  );
};

export default Main;
