import React, { useEffect } from "react";
import AOS from "aos";
import "../../../aos.css";

const MainLayout = ({ children }) => {
  useEffect(() => {
    AOS.init();
  });
  return <div className="wrap main">{children}</div>;
};

export default MainLayout;
